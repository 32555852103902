
<template>
  <lkps-table
    :api-endpoint="apiEndpoint"
    :headers="headers"
    :form="form"
    single-export="Dapat Ditinjau"
  />

</template>

<script>
import LkpsTable from '@views/pages/components/LKPSTable.vue'

export default {
  components: {
    LkpsTable,
  },
  data() {
    return {
      apiEndpoint: 'lkps_tkpl',
      headers: [
        [{
          label: 'Jenis Kemampuan',
          dataKey: 'jenis_kemampuan',
          order: 1,
          attributes: { rowspan: 2 },
        }, {
          label: 'Tingkat Kepuasan Pengguna (%)',
          attributes: { colspan: 4 },
        }, {
          label: 'Rencana Tindak Lanjut oleh PS dan/atau UPPS',
          dataKey: 'rencana_tindak_lanjut_oleh_ps_dan_atau_upps',
          order: 6,
          attributes: { rowspan: 2 },
        }],
        [{
          label: 'Sangat Baik',
          dataKey: 'sangat_baik',
          order: 2,
          dataAttributes: { class: 'text-center' }
        }, {
          label: 'Baik',
          dataKey: 'baik',
          order: 3,
          dataAttributes: { class: 'text-center' }
        }, {
          label: 'Cukup',
          dataKey: 'cukup',
          order: 4,
          dataAttributes: { class: 'text-center' }
        }, {
          label: 'Kurang',
          dataKey: 'kurang',
          order: 5,
          dataAttributes: { class: 'text-center' }
        }]
      ],
      form: {
        inputs: [{
          label: 'Jenis Kemampuan',
          name: 'jenis_kemampuan',
          dataKey: 'jenis_kemampuan',
          type: 'textarea',
          placeholder: 'Upaya pengembangan diri',
          rules: 'required',
        }, {
          label: 'Sangat Baik',
          name: 'sangat_baik',
          dataKey: 'sangat_baik',
          type: 'number',
          col: 3
        }, {
          label: 'Baik',
          name: 'baik',
          dataKey: 'baik',
          type: 'number',
          col: 3
        }, {
          label: 'Cukup',
          name: 'cukup',
          dataKey: 'cukup',
          type: 'number',
          col: 3
        }, {
          label: 'Kurang',
          name: 'kurang',
          dataKey: 'kurang',
          type: 'number',
          col: 3
        }, {
          label: 'Rencana Tindak Lanjut oleh PS dan/atau UPPS',
          name: 'rencana_tindak_lanjut_oleh_ps_dan_atau_upps',
          dataKey: 'rencana_tindak_lanjut_oleh_ps_dan_atau_upps',
          type: 'textarea',
          placeholder: 'Pelatihan kursus tentang pengembangan diri'
        }],
      }
    }
  },
}
</script>
